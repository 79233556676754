import { useRef, useState } from "react"
import styled from "styled-components"

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import { useDebouncedCallback } from "use-debounce"

import {
  HREF_MINUT_CONNECT_WITH_AIRBNB,
  HREF_MINUT_HELP_SHORTCODES,
} from "src/constants/hrefs"
import {
  usePostIntegrationLinkHomeConfirmedEvent,
  usePostIntegrationListingSearchedEvent,
} from "src/data/analytics/queries/integrationAnalyticsQueries"
import {
  getCommonAvailableThermostatModes,
  getDefaultThermostatMode,
} from "src/data/integrations/logic/integrations"
import { usePostIntegrationLink } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
  TIntegrationEntities,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { greyScale } from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

export function CreateLinkDialog({
  integration,
  open,
  homeName,
  homeId,
  relink,
  unlinkedEntities,
  onClose,
  nextCursor,
  hasCursor,
  fetchNextPage,
  loading,
  filter,
  setFilter,
}: {
  integration: TIntegration
  open: boolean
  homeName: string
  homeId: string
  relink: boolean
  unlinkedEntities: TIntegrationEntities
  onClose: () => void
  nextCursor?: string
  hasCursor: boolean
  fetchNextPage: () => void
  loading: boolean
  filter: string
  setFilter: (value: string) => void
}) {
  const { t, Trans, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const postListingSearchedEvent = usePostIntegrationListingSearchedEvent()

  const postListingSearchEventDebounced = useDebouncedCallback(() => {
    postListingSearchedEvent.mutate({
      context: "integration_page",
      integration_name: integration.name,
    })
  }, 1000)

  const createLinkMutation = usePostIntegrationLink()
  const patchHome = usePatchHome()

  const integrationId = integration?.integration_identifier
  const integrationName = integration?.name
  const integrationType = integration?.type

  const postIntegrationLinkHomeConfirmedEvent =
    usePostIntegrationLinkHomeConfirmedEvent()

  const [selectedId, setSelectedId] = useState("")
  const [lockLinkCreated, setLockLinkCreated] = useState(false)
  const [linkError, setLinkError] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedId(e.target.value)
  }

  const handleClose = () => {
    setSelectedId("")
    setFilter("")
    setLockLinkCreated(false)
    onClose()
  }

  const handleCreateLinkClick = () => {
    const selectedEntity = unlinkedEntities.find(
      (e) => e.entity_id === selectedId
    )
    if (!selectedEntity) {
      setLinkError(true)

      const error = new Error(
        `Expected entity_id ${selectedId} to be listed in unlinkedEntities`
      )

      return ErrorService.captureException(error, {
        context: {
          [integration.name]: {
            entity_id: selectedId,
            unlinkedEntities,
          },
        },
      })
    }

    const accountId = selectedEntity.account_id

    const devices = selectedEntity.devices || []
    const availableNestModes = getCommonAvailableThermostatModes(devices)
    const defaultMode = getDefaultThermostatMode(availableNestModes)

    createLinkMutation.mutate(
      {
        integration: integrationId,
        ownerType: OwnerType.ORGANIZATION,
        orgId,
        homeId: homeId,
        entityId: selectedId,
        accountId,
      },
      {
        onSuccess: () => {
          if (integrationType === "lock") {
            setLockLinkCreated(true)
          } else {
            handleClose()
          }
        },
      }
    )

    patchHome.mutate({
      orgId,
      homeId,
      data: { thermostat_mode_on_checkin: defaultMode },
    })

    postIntegrationLinkHomeConfirmedEvent.mutate({
      context: "integration_page",
      integration_name: integration.name,
      relink,
    })
  }

  const getEntityNickname = (id: string): string | undefined => {
    return unlinkedEntities.find((entity) => entity.entity_id === id)?.name
  }

  const searchFilterDebounced = useDebouncedCallback((s: string) => {
    if (!!s) {
      postListingSearchEventDebounced()
    }
  }, 400)

  function handleFilterChange(filter: string) {
    setFilter(filter)

    if (!!filter) {
      postListingSearchEventDebounced()
    }

    searchFilterDebounced(filter)
  }

  const infoAlert = (
    <div>
      <Trans
        i18nKey={t(langKeys.integrations_link_home_with_entity, {
          home: homeName,
          entity: getEntityNickname(selectedId),
        })}
        components={{ bold: <strong /> }}
      />
    </div>
  )

  const bottomRef = useRef<HTMLDivElement | null>(null)

  function handleLoadMoreClick() {
    fetchNextPage()
  }

  return lockLinkCreated ? (
    <ConfirmDialog
      open={open}
      title={t(langKeys.integrations_link_created_successfully)}
      fullWidth
      onConfirm={handleClose}
      onClose={handleClose}
      description={t(
        langKeys.integrations_add_integration_access_code_to_checkin_description,
        { integrationName }
      )}
      aria-labelledby="link-title"
      aria-describedby=""
    >
      <ExternalLink href={HREF_MINUT_HELP_SHORTCODES}>
        {t(langKeys.learn_more)}
      </ExternalLink>
    </ConfirmDialog>
  ) : (
    <MDialog
      open={open}
      title={t(langKeys.integrations_link_create)}
      onConfirm={handleCreateLinkClick}
      confirmLabel={t(langKeys.integrations_link_create)}
      loading={createLinkMutation.isLoading || patchHome.isLoading}
      confirmButtonProps={{
        disabled:
          createLinkMutation.isLoading ||
          patchHome.isLoading ||
          selectedId.length === 0,
      }}
      error={
        (createLinkMutation.isError || patchHome.isError || linkError) &&
        t(langKeys.failed_general_error_title)
      }
      description={
        <LinkUnitDialogDescription
          integration={integration}
          homeName={homeName}
        />
      }
      infoAlert={selectedId && infoAlert}
      onClose={handleClose}
      aria-labelledby="link-title"
      aria-describedby=""
    >
      <>
        <StyledTextField
          label={t(langKeys.integrations_filter_by_name_label)}
          value={filter}
          onChange={(value) => handleFilterChange(value)}
        />

        {!unlinkedEntities.length && (
          <MBanner type="warning">
            {t(langKeys.no_name_found_generic, {
              name: t(langKeys.integrations_integration_home, {
                name: integration.name,
              }),
            })}
          </MBanner>
        )}

        {!!unlinkedEntities.length && (
          <>
            {unlinkedEntities.length > 0 ? (
              <RadioGroup
                aria-label="entities"
                name="entities"
                value={selectedId}
                onChange={handleChange}
              >
                {unlinkedEntities.map((entity, index) => (
                  <StyledFormControlLabel
                    selected={entity.entity_id === selectedId}
                    key={`${entity.entity_id}-${index}`}
                    value={entity.entity_id}
                    control={<Radio />}
                    label={entity.name}
                  />
                ))}
              </RadioGroup>
            ) : (
              <MBanner type="warning" fullWidth>
                {t(langKeys.search_did_not_match_anything, {
                  search: `'${filter}'`,
                })}
              </MBanner>
            )}
          </>
        )}
        <div ref={bottomRef} />
      </>

      {hasCursor && (nextCursor || loading) && (
        <LoadMoreButton
          variant="minimal"
          onClick={handleLoadMoreClick}
          loading={loading}
        >
          {t(langKeys.load_more)}
        </LoadMoreButton>
      )}
    </MDialog>
  )
}

function LinkUnitDialogDescription({
  integration,
  homeName,
}: {
  integration: TIntegration
  homeName: string
}) {
  const { t, langKeys } = useTranslate()

  const defaultText = t(
    langKeys.integrations_select_entity_to_connect_to_home,
    {
      entity: integration.name,
      home: homeName,
    }
  )

  if (integration.integration_identifier === "airbnb") {
    return (
      <>
        {defaultText}
        {". "}
        {t(langKeys.integrations_select_entity_to_connect_to_home_airbnb)}{" "}
        <ExternalLink
          href={HREF_MINUT_CONNECT_WITH_AIRBNB}
          showExternalIcon={false}
          color="unset"
        >
          {t(langKeys.read_more)}
          {"."}
        </ExternalLink>
      </>
    )
  }

  return <>{defaultText}</>
}

const StyledFormControlLabel = styled(FormControlLabel)<{ selected: boolean }>`
  padding: ${spacing.XS} ${spacing.S};
  border-bottom: 1px solid ${greyScale[50]};

  .MuiButtonBase-root {
    margin-right: ${spacing.M};
  }

  .MuiFormControlLabel-label {
    font-weight: ${({ selected }) => (selected ? "500" : "400")};
  }
`

const LoadMoreButton = styled(MButton)`
  display: block;
  margin-top: ${spacing.M};
`

const StyledTextField = styled(MTextField)`
  margin: ${spacing.XS} 0;
`
