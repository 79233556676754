import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { homeGroupKeys } from "src/data/homeGroups/queries/homeGroupQueryCache"
import {
  IFetchHomeGroups,
  THomeGroup,
  THomeGroupFilter,
  TPatchHomeGroup,
  TPostHomeGroup,
  TPostImportHomeGroupHomes400Response,
  TPostImportHomeGroupHomesResponse,
} from "src/data/homeGroups/types/homeGroupTypes"
import { TSelectedPresetId } from "src/data/profileSettings/types/monitoringPresetTypes"
import { ErrorService } from "src/utils/ErrorService"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"
import { enabledChecker } from "src/utils/reactQueryUtil"

export function useFetchHomeGroups({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter: THomeGroupFilter
  options?: UseQueryOptions<
    IFetchHomeGroups,
    AxiosError,
    IFetchHomeGroups,
    ReturnType<typeof homeGroupKeys.homeGroupsList>
  >
}) {
  async function fetchHomeGroups(): Promise<IFetchHomeGroups> {
    const response = await minutApiHttpClient.get<IFetchHomeGroups>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery(
    homeGroupKeys.homeGroupsList(orgId, filter),
    fetchHomeGroups,
    {
      ...options,
    }
  )
}

export function useFetchHomeGroup({
  orgId,
  homeGroupId,
  options,
}: {
  orgId: string
  homeGroupId: string
  options?: UseQueryOptions<
    THomeGroup,
    AxiosError,
    THomeGroup,
    ReturnType<typeof homeGroupKeys.homeGroup>
  >
}) {
  async function fetchHomeGroup(): Promise<THomeGroup> {
    const response = await minutApiHttpClient.get<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`
    )

    return response.data
  }

  return useQuery(homeGroupKeys.homeGroup(orgId, homeGroupId), fetchHomeGroup, {
    ...options,
    enabled: enabledChecker(options, homeGroupId),
  })
}

interface IPostHomeGroupMutation {
  orgId: string
  body: TPostHomeGroup
}

export function usePostHomeGroup() {
  const queryClient = useQueryClient()

  async function postHomeGroup({
    orgId,
    body,
  }: IPostHomeGroupMutation): Promise<THomeGroup> {
    const response = await minutApiHttpClient.post<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups`,
      body
    )

    return response.data
  }

  return useMutation<THomeGroup, AxiosError, IPostHomeGroupMutation>(
    postHomeGroup,
    {
      onSuccess: (vars) => {
        return queryClient.invalidateQueries(
          homeGroupKeys.homeGroups(vars.organization_id)
        )
      },
      onError: (error) => {
        ErrorService.captureException(error)
      },
    }
  )
}

interface IPatchHomeGroupMutation {
  orgId: string
  homeGroupId: string
  body: TPatchHomeGroup
}
export function usePatchHomeGroup() {
  const queryClient = useQueryClient()

  async function patchHomeGroup({
    orgId,
    homeGroupId,
    body,
  }: IPatchHomeGroupMutation): Promise<THomeGroup> {
    const response = await minutApiHttpClient.patch<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`,
      body
    )

    return response.data
  }

  return useMutation<THomeGroup, AxiosError, IPatchHomeGroupMutation>(
    patchHomeGroup,
    {
      onSuccess: (_, { orgId }) => {
        return queryClient.invalidateQueries(homeGroupKeys.homeGroups(orgId))
      },
    }
  )
}

interface IDeleteHomeGroupMutation {
  orgId: string
  homeGroupId: string
}
export function useDeleteHomeGroup() {
  const cache = useQueryClient()

  async function deleteHomeGroup({
    orgId,
    homeGroupId,
  }: IDeleteHomeGroupMutation): Promise<void> {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`
    )
    return response.data
  }

  return useMutation<
    void,
    AxiosError<{ error_key: "homegroup_not_empty" }>,
    IDeleteHomeGroupMutation
  >(deleteHomeGroup, {
    onSuccess(_, vars) {
      cache.invalidateQueries(homeGroupKeys.homeGroups(vars.orgId))
    },
  })
}

export function usePostImportHomeGroupHomes(args?: { dryRun?: boolean }) {
  async function postImportHomeGroupHomes({
    orgId,
    homeGroupId,
    selectedPresetId,
    csv,
  }: {
    orgId: string
    homeGroupId: string
    selectedPresetId: TSelectedPresetId
    csv: File
  }) {
    const csvText = await csv.text()

    const response = await minutApiHttpClient.post<
      TPostImportHomeGroupHomesResponse,
      AxiosResponse<TPostImportHomeGroupHomesResponse>,
      string
    >(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}/csv/homes`,
      csvText,
      {
        params: {
          dry_run: args?.dryRun,
          profile_id: selectedPresetId.noise,
        },
        headers: {
          "Content-Type": "text/csv",
        },
      }
    )

    return {
      ...response.data,
      // The BE returns the wrong number for dry runs, so we need to calculate it ourselves
      total_homes_imported: csvText.split("\n").length - 1,
    }
  }

  return useMutation<
    TPostImportHomeGroupHomesResponse,
    AxiosError<TPostImportHomeGroupHomes400Response>,
    {
      orgId: string
      homeGroupId: string
      selectedPresetId: TSelectedPresetId
      csv: File
    }
  >({
    mutationFn: postImportHomeGroupHomes,
  })
}
