import { Fragment, useEffect, useMemo, useState } from "react"

import {
  ColumnSortWrapper,
  IconWrapper,
  UnsortedIconsWrapper,
} from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import ChevronIcon from "src/ui/icons/chevron-down.svg"
import { localStorageFactory } from "src/utils/storageUtil"
import { Maybe } from "src/utils/tsUtil"

export type TableColumn<T> = {
  value: string
  label: string
  renderLabel?: (label: string) => React.ReactNode
  sortButtonSpacing?: "compact" | "grow"
  hidden?: boolean
  disabled?: boolean
  columnWidth?: number | string
  enableSort?: boolean
  render: (data: T) => React.ReactNode
  disableClickPropagation?: boolean
}

export type TTableSort = {
  id: string
  order: "desc" | "asc"
}

export function useTableColumns<T>({
  columns,
  data,
  sort,
  onSortChange,
  options,
}: {
  columns: TableColumn<T>[]
  data?: T[]
  sort?: Maybe<TTableSort>
  onSortChange?: (sort: TTableSort) => void
  options?: {
    localStorageKey?: string
  }
}) {
  const localStorage = localStorageFactory<{ [key: string]: boolean }>({
    key: options?.localStorageKey ?? "",
  })

  const [hiddenColumns, setHiddenColumns] = useState<{
    [key: string]: boolean
  }>(() => {
    if (options?.localStorageKey) {
      const columnsInStorage = localStorage.get()

      if (columnsInStorage) {
        return columnsInStorage
      }
    }

    return Object.fromEntries(columns.map((c) => [c.value, !!c.hidden]))
  })

  useEffect(() => {
    localStorage.set(hiddenColumns)
  }, [hiddenColumns, localStorage])

  const interactiveColumns = columns.filter((c) => !c.disabled)
  const visibleColumns = columns.filter(
    (c) => !c.hidden && !hiddenColumns[c.value]
  )
  const interactiveVisibleColumns = interactiveColumns.filter(
    (c) => !c.hidden && !hiddenColumns[c.value]
  )

  const headerElements = visibleColumns.map((c) => {
    const label = c.renderLabel?.(c.label) ?? c.label

    if (onSortChange && sort && sort.id === c.value) {
      return (
        <ColumnSortWrapper
          key={c.value}
          $justify={c.sortButtonSpacing}
          onClick={() =>
            onSortChange?.({
              id: c.value,
              order: sort.order === "desc" ? "asc" : "desc",
            })
          }
        >
          <div>{label}</div>
          <IconWrapper $up={sort?.order === "asc"}>
            <ChevronIcon width={10} />
          </IconWrapper>
        </ColumnSortWrapper>
      )
    }

    if (onSortChange && c.enableSort) {
      return (
        <ColumnSortWrapper
          key={c.value}
          $justify={c.sortButtonSpacing}
          onClick={() =>
            onSortChange?.({
              id: c.value,
              order: "desc",
            })
          }
        >
          <div>{label}</div>
          <UnsortedIconsWrapper>
            <IconWrapper $up>
              <ChevronIcon width={8} />
            </IconWrapper>
            <IconWrapper>
              <ChevronIcon width={8} />
            </IconWrapper>
          </UnsortedIconsWrapper>
        </ColumnSortWrapper>
      )
    }

    return (
      <div key={c.value}>
        <div>{label}</div>
      </div>
    )
  })

  const rows = useMemo(() => {
    if (data) {
      return data.flatMap((d, i) => (
        <Fragment key={i}>
          {visibleColumns.map((c) =>
            c.disableClickPropagation ? (
              <div key={c.value} onClick={(e) => e.stopPropagation()}>
                {c.render(d)}
              </div>
            ) : (
              <Fragment key={c.value}>{c.render(d)}</Fragment>
            )
          )}
        </Fragment>
      ))
    }
  }, [data, visibleColumns])

  const templateColumns = useMemo(() => {
    const strArr = visibleColumns.map((c, i) => {
      // Make sure the right most column expand to the right, if for example is set to "min-contents"
      if (i === visibleColumns.length - 1) {
        return "auto"
      }

      if (c.columnWidth) {
        if (typeof c.columnWidth === "string") {
          return `${c.columnWidth}`
        }

        return `${c.columnWidth}px`
      }

      return "minmax(300px, 1fr)"
    })

    return strArr.join(" ")
  }, [visibleColumns])

  function updateColumnVisibility(columnValue: string, hidden: boolean) {
    setHiddenColumns((prev) => {
      return {
        ...prev,
        [columnValue]: hidden,
      }
    })
  }

  return {
    visibleColumns,
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    updateColumnVisibility,
    templateColumns,
  }
}
