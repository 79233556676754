import styled from "styled-components"

import { useAppData } from "src/context/useAppData"
import { CSVRowError } from "src/data/homeGroups/types/homeGroupTypes"
import { useFetchHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { Dropzone } from "src/ui/Dropzone/Dropzone"
import { ExpandableFileRequirements } from "src/ui/ExpandableFileRequirements/ExpandableFileRequirements"
import ImportantIcon from "src/ui/icons/important-outlined.svg"
import LightIcon from "src/ui/icons/lightbulb.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export const UPLOAD_FORM_ID = "home-group-building-upload-form"

export function HomeGroupBuildingUploadFileStep({
  selectedFile,
  setSelectedFile,
  isUploading,
  validationErrors,
  hasInsufficientHomeTokens,
  onSubmit,
  loading,
  error,
}: {
  selectedFile: File | null
  setSelectedFile: (file: File | null) => void
  onSubmit: () => void
  isUploading: boolean
  validationErrors: CSVRowError[]
  hasInsufficientHomeTokens: boolean
  loading: boolean
  error: boolean
}) {
  const { t, langKeys } = useTranslate()

  const { currentSubscription } = useAppData()
  const { orgId } = useOrganization()

  const fetchHomeTokens = useFetchHomeTokens({
    orgId,
    filters: {
      activated: false,
    },
  })

  const homeTokensCount = fetchHomeTokens.data?.paging.total_count || 0
  const hasPlanPerUnit =
    currentSubscription?.billing_model === "plan_unit_per_home"

  const importErrors = getImportErrors({
    hasInsufficientHomeTokens,
    validationErrors,
    homeTokensCount,
    t,
  })

  const isValidationError =
    !hasInsufficientHomeTokens && importErrors.length > 0

  // The query is disabled for non-hometoken users. Therefore we check `isInitialLoading`
  if (fetchHomeTokens.isInitialLoading || loading) {
    return (
      <LoadingWrapper>
        <MSkeleton variant="rect" height={20} />
        <MSkeleton variant="rect" />
        <MSkeleton variant="rect" />
      </LoadingWrapper>
    )
  }

  if (error || fetchHomeTokens.isError) {
    return (
      <div>
        <MText variant="heading2" marginBottom={spacing.L}>
          {t(langKeys.home_group_building_create_home_title)}
        </MText>

        <MBanner type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      </div>
    )
  }

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.home_group_import_homes_title)}
      </MText>
      <MText marginBottom={spacing.L}>
        {hasPlanPerUnit
          ? t(langKeys.home_group_import_homes_description, {
              count: homeTokensCount,
            })
          : t(langKeys.home_group_import_homes_description_contract)}
      </MText>

      {importErrors.length > 0 && (
        <ValidationErrorBanner
          type="error"
          fullWidth
          title={t(langKeys.home_group_import_homes_validation_error_title)}
          icon={<ImportantIcon width={24} />}
        >
          <StyledUl>
            {importErrors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </StyledUl>
        </ValidationErrorBanner>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault()

          if (selectedFile && selectedFile.type === "text/csv") {
            onSubmit()
          }
        }}
        id={UPLOAD_FORM_ID}
      >
        <Dropzone
          file={selectedFile}
          onChange={setSelectedFile}
          loading={isUploading}
          inputProps={{ accept: "text/csv", required: true }}
          error={
            selectedFile && selectedFile.type !== "text/csv"
              ? t(langKeys.home_group_import_homes_invalid_file)
              : undefined
          }
        />
      </form>

      {!isUploading && (
        <InfoWrapper>
          <MBanner type="info" fullWidth>
            <TemplateWrapper>
              <LightIcon width={24} />
              <MText variant="subtitle">
                {t(langKeys.home_group_import_homes_template_title)}
              </MText>
              <ExternalLink href="" download showExternalIcon={false}>
                {t(langKeys.home_group_import_homes_template_link)}
              </ExternalLink>
            </TemplateWrapper>
          </MBanner>

          <ExpandableFileRequirements
            description={t(
              langKeys.home_group_import_homes_file_requirements_description
            )}
            initialOpen={isValidationError}
            columns={getFileRequiremntsColumns(t)}
          />
        </InfoWrapper>
      )}
    </div>
  )
}

function getImportErrors({
  hasInsufficientHomeTokens,
  validationErrors,
  homeTokensCount,
  t,
}: {
  hasInsufficientHomeTokens: boolean
  validationErrors: CSVRowError[]
  homeTokensCount: number
  t: TTranslateFunction
}) {
  if (hasInsufficientHomeTokens) {
    return [
      t(langKeys.home_group_import_homes_insufficient_home_tokens, {
        count: homeTokensCount,
      }),
    ]
  }

  return validationErrors.map((error) => {
    return t(langKeys.home_group_import_homes_error_line, {
      line: error.row,
      error: error.error,
    })
  })
}

function getFileRequiremntsColumns(t: TTranslateFunction) {
  return [
    {
      name: t(langKeys.home_name),
      description: t(langKeys.home_group_import_homes_home_name_description),
      required: true,
    },
    {
      name: t(langKeys.home_type),
      description: t(langKeys.home_group_import_homes_home_type_description),
      required: true,
    },
    {
      name: t(langKeys.home_group_import_homes_floor),
      description: t(langKeys.home_group_import_homes_floor_description),
      required: true,
    },
    {
      name: t(langKeys.home_group_import_homes_apt_number),
      description: t(langKeys.home_group_import_homes_apt_number_description),
      required: true,
    },
    {
      name: t(langKeys.home_group_import_homes_noise_preset),
      description: t(langKeys.home_group_import_homes_noise_preset_description),
      required: true,
    },
  ]
}

const InfoWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
  margin-top: ${spacing.L};
`

const TemplateWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: ${spacing.XS};
`

const ValidationErrorBanner = styled(MBanner)`
  margin-bottom: ${spacing.L};
`

const StyledUl = styled.ul`
  margin: 0;
`

const LoadingWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
